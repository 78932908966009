import React from "react";
import "../contact/contact.css";
import { Link as ScrollLink } from "react-scroll";

const Contact = () => {
 
  const handlePhoneNumberClick = () => {
    window.location.href = "tel:+0040733263080";
  };

  return (
    <div className="contactare" id="contact">
      <h2 className="heading">Contact</h2>
      <h3 className="form-text">
        Scrie-ne cu ce te putem ajuta și te vom contacta
      </h3>
      <div className="contact-desc">
        
        <div className="info">
          <h3>Apelează cu încredere</h3>
          <div className="social-contact">
            <div className="icon">
            <i className="fa-solid fa-user "></i>
              <span>Illes Attila Andrei</span>
            </div>
            <div className="icon">
              {/* Telefon */}

              <i class="bx bxs-phone" ></i>
              <span onClick={handlePhoneNumberClick}>0733 263 080</span>
            </div>
            <div className="icon">
              {/* Email */}

              <i class="bx bxs-envelope"></i>
              <span>yanisdavid_construct@yahoo.com</span>
            </div>
          
            
          </div>
        </div>
       
        
      </div>

      <ScrollLink to="home" smooth={1000} className="btn-up">
        <i className="bx bxs-up-arrow-square"></i>
      </ScrollLink>
      <div className="design">

      <i className="copy-right">Copyright @2023</i>
      <i className="design-link">Create by <a href="https://www.linkedin.com/in/danielsima13/">Daniel M. Sima</a></i>
      </div>
    </div>
  );
};

export default Contact;
