import React from 'react'
import Nav from "../header/Nav"
import Main from "../main/Main"
import About from "../about/About"
import Services from "../services/Services"
import Contact from "../contact/Contact"
import banner from "../main/Banner-Startup.jpg"


const LandingPage = () => {
   
        return (
          <>
            <Nav />
            <Main />
            <About />
            <Services  />
            <Contact />
          </>
        );
      };


export default LandingPage