import "../main/main.css";
import React from "react";
import casa from "../main/house.png";
import banner from "./Banner-Startup.jpg"

const Main = () => {
  return (
    <>
     <div className="banner" >
              <img src={banner} style={{width:"100%", height:"100%" ,  objectFit:"contain"}}/>
            </div>
    <div className="home" id="home">
      
      <div className="home-content">
        <h2>Bună, noi suntem</h2>
        <h1>SC Yanis și David Construct SRL</h1>
        <h3>
          și suntem o firmă de <span>construcții</span>
        </h3>
        <p className="bigP">
          <span>Ridicăm standardele în construcții</span>
        </p>
        <p>
          Bine ați venit pe site-ul nostru de construcții! Suntem o echipă
          dedicată și experimentată în industria construcțiilor, oferind
          servicii complete și profesionale pentru proiectele de construcție.
          Indiferent dacă aveți nevoie de construcții rezidențiale, comerciale
          sau industriale, suntem aici pentru a vă ajută să transformați ideile
          în realitate.
        </p>
      </div>
      <div className="home-img">
        <img src={casa} className="home-image" alt="Casa" />
      </div>
    </div>
    </>
  );
};

export default Main;
