import "../about/about.css";
import logo from "../about/house2.png";

const About = () => {
  return (
    <div className="about" id="about">
      <h2 className="heading">
        Despre <span>noi</span>
      </h2>
      <div className="about-desc">
        <div className="about-img">
          <img src={logo} />
        </div>
        <div className="about-content">
          <h3>
            Echipa noastră de construcții este formată din profesioniști cu
            experiență și pasionați de arta construirii. Cu o combinație de
            cunoștințe tehnice solide și abilități practice, suntem dedicați să
            oferim servicii de construcții de înaltă calitate.
          </h3>
          
          <p>
            Fie că este vorba de construcții rezidențiale, comerciale sau
            industriale, echipa noastră pune accent pe excelență și atenție la
            detalii în fiecare etapă a proiectului. De la planificare și
            proiectare, până la execuție și finalizare, ne asigurăm că fiecare
            structură pe care o construim este durabilă, funcțională și estetic
            plăcută.
          </p>
          <p>
            Colaborăm strâns cu clienții noștri, ascultându-le nevoile și
            cerințele, pentru a le oferi soluții personalizate și adaptate
            proiectelor lor. Având în vedere eficiența și respectarea
            termenelor, ne străduim să livrăm proiectele la timp și în limitele
            bugetului stabilit.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
