import React, {useState} from "react";
import {Link as ScrollLink} from "react-scroll";
import { Link } from 'react-router-dom';
import '../header/nav.css'

const Nav = () => {

    
    const [isMobile, setIsMobile] = useState(false);
    const closeMobileMenu = () => {
        setIsMobile(false);
      };
    return(
     <div className='nav' id="nav">
        <ScrollLink to= "home" className='logo'>Yanis și David Constrcut</ScrollLink>
        <ul className={isMobile? "navbar-mobile" : "navbar"}
        onClick={()=> setIsMobile(false)}>
           
           <li className="nav-items"> <ScrollLink to="home" offset={-100} smooth={true} duration={1000} onClick={closeMobileMenu} className="active">Acasă</ScrollLink></li>
           <li className="nav-items"> <ScrollLink to="about" offset={-100} smooth={true} duration={1000} onClick={closeMobileMenu} className='about-nav'>Despre noi</ScrollLink></li>
           <li className="nav-items"> <ScrollLink to="servicii"  offset={-60}  smooth={true} duration={1000} onClick={closeMobileMenu} className='services'>Servicii</ScrollLink></li>
           <li className="nav-items"> <ScrollLink to="contact" smooth={true} duration={1000} onClick={closeMobileMenu} className='contact'>Contact</ScrollLink></li>
            
        </ul>
       
        <button className='mobile-menu-icon'
        onClick={()=>setIsMobile(!isMobile)}> 
            {isMobile ? (<i className='fas fa-times'></i>) 
            : 
            (<i className='fas fa-bars'></i>)
            }
        </button>
     </div>
    )
}


export default Nav;