import "../PagesRander/styleRenderServicesLayout.css";
import { useState, useRef, useEffect } from "react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";



function Serviciu(props) {
  const { servicii, photos } = props;
  const serviciuCurent = servicii[props.serviciu];

  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [currentImageSrc, setCurrentImageSrc] = useState(
    photos[0]?.src || ""
  );

  const mainProductImageRef = useRef(null);

  useEffect(() => {
    if (currentImage >= 0 && currentImage < photos.length) {
      setCurrentImageSrc(photos[currentImage]?.src || "");
    }
  }, [currentImage, photos]);

  const openModal = () => {
    setModalOpen(true);
  };

  const changeImages = (index) => {
    setCurrentImage(index);
    setCurrentImageSrc(photos[index]?.src || "");
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const changeImage = (increment) => {
    const newIndex = currentImage + increment;

    if (newIndex >= 0 && newIndex < photos.length) {
      setCurrentImage(newIndex);
      setCurrentImageSrc(photos[newIndex]?.src || "");
    }
  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <div className="nav-container">
           <button className="back-link" onClick={goBack}>
               &lt;
          </button>
          <h1>{serviciuCurent?.titlu}</h1>

      </div>
           <div className="main-description">

               <div className="description-text">
                  <p>{serviciuCurent?.descriere1}</p>
                  <p>{serviciuCurent?.descriere2}</p>

                </div>
            
            
                <div className="imagine-serviciu">
                   <img src={serviciuCurent?.photoService.src}/>
                 </div>

                 </div>

                 
          <div className="card-image">
            
              <div className="main_image">
                     <button
                className="previous-btn"
                onClick={() => changeImage(-1)}
                disabled={currentImage === 0}
                  >
                &lt;
                 </button>
                  <img
                  ref={mainProductImageRef}
                  src={currentImageSrc}
                  id="main_product_image"
                 
                  alt="Main Product"
                  onClick={openModal}
                  />
                       <button
                className="next-btn"
                onClick={() => changeImage(1)}
                disabled={currentImage === photos.length - 1}
              >
                &gt;
              </button>
              </div>
              <div className="thumbnail_images">
                <ul id="thumbnail">
                  {serviciuCurent.photos.map((photo, index) => (
                    <li key={index}>
                      <img
                        src={photo.src}
                       
                        alt={photo.title}
                        onClick={() => changeImages(index)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
    
     
        
            
          </div>
       
       
      

      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <div className="modal-image">
              <img
                src={photos[currentImage]?.src || ""}
                alt={photos[currentImage]?.title || ""}
              />
            </div>
            <div className="modal-navigation">
              <button
                className="previous-button"
                onClick={() => changeImage(-1)}
                disabled={currentImage === 0}
              >
                &lt;
              </button>
              <button
                className="next-button"
                onClick={() => changeImage(1)}
                disabled={currentImage === photos.length - 1}
              >
                &gt;
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Serviciu;
