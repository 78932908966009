import React from 'react';
import '../services/servicii.css'
import electric from "../services/icons/electric.png"
import roof from "../services/icons/roof.png"
import renovari from "../services/icons/renovari.png"
import casa from "../services/icons/casa.png"
import excavator from "../services/icons/excavator.png"
import { Link } from 'react-router-dom';


const Services= () => {


   
    return(
     <div className="servicii" id="servicii">
        <h2 className='heading'>Servicile <span>noastre</span></h2>
     <div className='services-container'>
      
      <div className="services-box">
      <img src={casa} alt='icon' />
        <h3>Construcții case</h3>
      
     
     <Link to="/servicii/caserosu" className='link-pages'>Afișează lucrări</Link>
   
        
      </div>
      
      <div className="services-box">
      <img src={renovari}alt='icon'/>
        <h3>Renovări/Consolidări/Reabilitări</h3>
        
        <Link to="/servicii/renovari" className='link-pages'>Afișează lucrări</Link>
      </div>
      
      <div className="services-box">
      <i class='bx bxs-thermometer'></i>
        <h3>Termoizolații și hidroizolații</h3>
       
        <Link to="/servicii/hidroizolatie" className='link-pages'>Afișează lucrări</Link>
      </div>
     
      <div className="services-box">
      <img src={electric} alt='icon'/>
        <h3>Instalații electrice, sanitare și termice</h3>
        <Link to="/servicii/instalatii" className='link-pages'>Afișează lucrări</Link>
        
      </div>
     
      <div className="services-box">
      <img src={roof} alt='icon'/>
        <h3>Acoperișuri</h3>
        <Link to="/servicii/tamplarie"className='link-pages' >Afișează lucrări</Link>
        
      </div>
      
      <div className="services-box">
      <img  src={excavator} alt='icon'/>
        <h3 className="sapaturi"  > Săpături,fose,fundații,{'\n'} drenaje,piconări,decopertări</h3>
        <Link to="/servicii/sapaturi"className='link-pages' >Afișează lucrări</Link>
       
      </div>
     </div>
           
     
  
     </div>
    )
}


export default Services;