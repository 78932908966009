
import poza1 from '../images-data/case1.jpeg';
import poza2 from '../images-data/case2.jpeg';
import poza3 from '../images-data/case3.jpeg';
import poza4 from '../images-data/case4.jpeg';
import poza5 from '../images-data/case5.jpeg';
import poza66 from '../images-data/case6.jpeg';
import poza77 from '../images-data/case7.jpeg';
import poza88 from '../images-data/case8.jpeg';
import poza99 from '../images-data/case9.jpeg';
import poza100 from '../images-data/case10.jpeg';
import poza101 from '../images-data/case11.jpeg';

import serviciu1 from "../images-data/houseService.png"

import poza6 from "../images-data/renovari4.jpeg"
import poza7 from "../images-data/renovari3.jpeg"
import poza8 from "../images-data/renovari2.jpeg"
import poza9 from "../images-data/renovari5.jpeg"
import poza10 from "../images-data/renovari1.jpeg"
import poza1010 from "../images-data/renovareap.jpeg"
import serviciu2 from "../images-data/house-renovations.png"

import poza11 from "../images-data/termoizolatie1.jpeg"
import poza12 from "../images-data/termoizolatie.jpg"
import poza13 from "../images-data/termoizolatie2.jpeg"
import poza14 from "../images-data/termoizolatie1.jpeg"
import poza15 from "../images-data/termoizolatie.jpg"

import serviciu3 from "../images-data/termoizolatie-serviciu.jpg"

import poza16 from "../images-data/DSCN2078-scaled.jpg"
import poza17 from "../images-data/Electrice_2.png"
import poza18 from "../images-data/executia-instalatiei-electrice.jpg"
import poza19 from "../images-data/DSCN2078-scaled.jpg"
import poza20 from "../images-data/Electrice_2.png"

import poza21 from "../images-data/acoperis2.jpeg"
import poza22 from '../images-data/acoperis3.jpeg';
import poza23 from '../images-data/acoperis4.jpeg';
import poza24 from '../images-data/acoperis5.jpeg';
import poza25 from "../images-data/acoperis6.jpeg"
import poza252 from "../images-data/acoperis1.jpeg";
import poza253 from "../images-data/acoperis7.jpeg"
import poza254 from "../images-data/acoperis9.jpg"

import poza26 from "../images-data/fundatie1.jpeg"
import poza27  from "../images-data/fundatie2.jpeg"
import poza28 from "../images-data/fundatie3.jpeg"
import poza29  from "../images-data/fundatie4.jpeg"
import poza30  from "../images-data/fundatie5.jpeg"
import poza301  from "../images-data/fundatie6.jpeg"
import poza302  from "../images-data/fundatie7.jpeg"
import poza303  from "../images-data/fundatie8.jpeg"
import poza304  from "../images-data/fundatie9.jpeg"
import poza305  from "../images-data/fundatie10.jpeg"
import serviciu6 from "../images-data/excavator.png"



 const servicii = {
  serviciu1: {
    titlu: 'Construcții case',
    descriere1: '● Aici va prezentăm următoarele proiecte terminate la statiul de ROȘU, GRI, CULOARE',
    descriere2:
      '● Beton/cărămidă/B.C.A/lemn Totul, de la fundație la acoperiș.',
    photos: [
      { src: poza1, title: 'Imagine 1' },
      { src: poza2, title: 'Imagine 2' },
      { src: poza3, title: 'Imagine 3' },
      { src: poza4, title: 'Imagine 4' },
      { src: poza5, title: 'Imagine 5' },
      { src: poza66, title: 'Imagine 6' },
      { src: poza77, title: 'Imagine 7' },
      { src: poza88, title: 'Imagine 8' },
      { src: poza99, title: 'Imagine 9' },
      { src: poza100, title: 'Imagine 10' },
      { src: poza101, title: 'Imagine 11' }
      
    ],
    photoService :  { src: serviciu1, title: 'Imagine 1' }
  },
  serviciu2: {
    titlu: 'Renovări/Consolidări/Reabilitări',
    descriere1: '● Finisaje executate cu grijă la cel mai mic detaliu.',
    descriere2:
      '● Se va face o evaluare pentru a stabili cu exactitate problemele și cea mai bună metodă de rezolvare a acestora.',
    photos: [
      { src: poza6, title: 'Imagine 1' },
      { src: poza7, title: 'Imagine 2' },
      { src: poza8, title: 'Imagine 3' },
      { src: poza9, title: 'Imagine 4' },
      { src: poza10, title: 'Imagine 5' },
      { src: poza1010, title: 'Imagine 6' }
    ],
    photoService :  { src: serviciu2, title: 'Imagine 2' }
  },
  serviciu3: {
    titlu: 'Termoizolații și hidroizolatii.',
    descriere1: '● Experiență ne recomandă și pe partea de termoizolații și hidroizolatii.',
    descriere2:
      '● Se va face o evaluare pentru a stabili cu exactitate problemele și cea mai bună metodă de rezolvare a acestora.',
    photos: [
      { src: poza11, title: 'Imagine 1' },
      { src: poza12, title: 'Imagine 2' },
      { src: poza13, title: 'Imagine 3' },
      { src: poza14, title: 'Imagine 4' },
      { src: poza15, title: 'Imagine 5' }
    ],
    photoService :  { src: serviciu3, title: 'Imagine 3' }
  },
  serviciu4: {
    titlu: 'Electrice/sanitare/termice',
    descriere1: '● Experiență ne recomandă și pe partea de electricitate, instalații sanitare și termice.',
    descriere2:
      '● Se va face o evaluare pentru a stabili cu exactitate problemele și cea mai bună metodă de rezolvare a acestora.',
    photos: [
      { src: poza16, title: 'Imagine 1' },
      { src: poza17, title: 'Imagine 2' },
      { src: poza18, title: 'Imagine 3' },
      { src: poza19, title: 'Imagine 4' },
      { src: poza20, title: 'Imagine 5' }
    ],
    photoService :  { src: serviciu1, title: 'Imagine 4' }
  },
  serviciu5: {
    titlu: 'Acoperisuri',
    descriere1: '● Experiență ne recomandă și pe partea de acoperișuri.',
    descriere2:
      '● Se va face o evaluare pentru a stabili cu exactitate problemele și cea mai bună metodă de rezolvare a acestora.',
    photos: [
      { src: poza21, title: 'Imagine 1' },
      { src: poza22, title: 'Imagine 2' },
      { src: poza23, title: 'Imagine 3' },
      { src: poza24, title: 'Imagine 4' },
      { src: poza25, title: 'Imagine 5' },
      { src: poza252, title: 'Imagine 6' },
      { src: poza253, title: 'Imagine 7' },
      { src: poza254, title: 'Imagine 8' }
    ],
    photoService :  { src: serviciu1, title: 'Imagine 5' }
  },
  serviciu6: {
    titlu: 'Săpături, fundații',
    descriere1: '● Tot ceea ce ține de săpături: ',
    descriere2:
      '- fose, fundații, drenaje, piconări, decopertări. ',
    photos: [
      { src: poza26, title: 'Imagine 1' },
      { src: poza27, title: 'Imagine 2' },
      { src: poza28, title: 'Imagine 3' },
      { src: poza29, title: 'Imagine 4' },
      { src: poza30, title: 'Imagine 5' },
      { src: poza301, title: 'Imagine 6' },
      { src: poza302, title: 'Imagine 7' },
      { src: poza303, title: 'Imagine 8' },
      { src: poza304, title: 'Imagine 9' },
      { src: poza305, title: 'Imagine 10' }
    ],
    photoService :  { src: serviciu6, title: 'Imagine 6' }
  },
};

export default servicii;