import { BrowserRouter, Routes, Route } from 'react-router-dom';

import LandingPage from "../src/LandingPage/LandingPage"
import RenderServicesLayout from './PagesRander/RenderServicesLayout';

import servicii from './services/Services-data/services-data';

export default function Router() {




  const BrowserRoutes = () => {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/servicii/caserosu" element={<RenderServicesLayout servicii={servicii} photos={servicii.serviciu1.photos}  serviciu="serviciu1" />} />
            <Route path="/servicii/renovari" element={<RenderServicesLayout servicii={servicii} photos={servicii.serviciu2.photos} serviciu="serviciu2" />} />
            <Route path="/servicii/hidroizolatie" element={<RenderServicesLayout servicii={servicii} photos={servicii.serviciu3.photos} serviciu="serviciu3" />} />
            <Route path="/servicii/instalatii" element={<RenderServicesLayout servicii={servicii} photos={servicii.serviciu4.photos} serviciu="serviciu4" />} />
            <Route path="/servicii/tamplarie" element={<RenderServicesLayout servicii={servicii} photos={servicii.serviciu5.photos} serviciu="serviciu5" />} />
            <Route path="/servicii/sapaturi" element={<RenderServicesLayout servicii={servicii} photos={servicii.serviciu6.photos} serviciu="serviciu6" />} />

          </Routes>
        </BrowserRouter>
      </>
    );
  };

  return <BrowserRoutes />;
}
